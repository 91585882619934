import { Client, Message } from "@stomp/stompjs";
import { BrokerConnector } from "./BrokerConnector";
import { Event } from "./Event";
import { ILogMessages } from "./ILogMessages";
import { ImagePreloader } from "./ImagePreloader";
import { Reward } from "./Reward";

const appVersion = process.env.REACT_APP_VERSION;

export interface IListenDailyPlannerEvents {
  onEventsChanged(events: Event[]): void;
  onRewardEarned(reward: Reward): void;
}

export type DailyPlannerOptions = {
  brokerUrl: string;
  brokerUsername: string;
  brokerPassword: string;
  logger?: ILogMessages;
};

export class DailyPlanner {
  private logger?: ILogMessages;
  private connector: BrokerConnector;
  private events: Event[];
  private listener?: IListenDailyPlannerEvents;
  private imagePreloader: ImagePreloader;

  public constructor({
    brokerUrl,
    brokerUsername,
    brokerPassword,
    logger,
  }: DailyPlannerOptions) {
    this.logger = logger;
    this.events = [];
    this.imagePreloader = new ImagePreloader();
    this.connector = new BrokerConnector({
      url: brokerUrl,
      username: brokerUsername,
      password: brokerPassword,
      logger: this.logger,
      setup: (client) => this.setupConnection(client),
    });
  }

  public setListener(listener: IListenDailyPlannerEvents): void {
    this.log("listener set");
    this.listener = listener;
  }

  public getEvents(): Event[] {
    return this.events;
  }

  public toggleEvent(event: Event) {
    // optimistic response
    event.isDone = !event.isDone;
    this.listener?.onEventsChanged(this.events);
    if (event.isDone) {
      this.listener?.onRewardEarned(event.reward);
    }

    if (!event.isDone) {
      this.connector.getClient().publish({
        destination: "/exchange/DailyPlanner/RestartActivity",
        body: JSON.stringify({ id: event.id }),
      });
    } else {
      this.connector.getClient().publish({
        destination: "/exchange/DailyPlanner/FinishActivity",
        body: JSON.stringify({ id: event.id }),
      });
    }
  }

  private setupConnection(client: Client): void {
    client.subscribe("/exchange/DailyPlanner/ViewerUpdated", (message) => {
      this.onViewerUpdated(message);
    });

    client.subscribe("/exchange/DailyPlanner/SyncSchedule", (message) => {
      this.onSyncSchedule(message);
    });

    client.subscribe("/exchange/DailyPlanner/RewardEarned", (message) => {
      this.onRewardEarned(message);
    });

    // client.subscribe("/exchange/DailyPlanner/EventAdded", (message) => {
    //   this.onEventAdded(message);
    // });

    client.publish({
      destination: "/exchange/DailyPlanner/ViewerStarted",
      body: JSON.stringify({
        version: appVersion,
      }),
    });
  }

  private onViewerUpdated(message: Message) {
    this.log("onViewerUpdated", message);

    const body = JSON.parse(message.body);
    if (body.force) {
      window.location.reload();
    }

    if (body.version !== appVersion) {
      window.location.reload();
    }
  }

  private onSyncSchedule(message: Message) {
    this.log("onSyncSchedule", message);
    this.events = JSON.parse(message.body);
    this.listener?.onEventsChanged(this.events);

    for (const event of this.events) {
      this.imagePreloader.preload(event.reward.url);
    }
  }

  private onRewardEarned(message: Message) {
    this.log("onRewardEarned", message);
    const reward = JSON.parse(message.body);
    this.listener?.onRewardEarned(reward);
  }

  // private onEventAdded(message: Message) {
  //   this.log("onEventAdded", message);
  //   this.events.push({ title: "some event", isDone: false });
  //   this.listener?.onEventsChanged(this.events);
  // }

  private log(...values: any[]) {
    return this.logger?.log("DailyPlanner", ...values);
  }
}
