import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import { Root } from "./components/Root";
import { broker } from "./config/broker";
import { logger } from "./config/logger";
import { theme } from "./config/theme";
import { DailyPlanner } from "./domain/DailyPlanner";
import "./index.css";

const app = new DailyPlanner({
  brokerUrl: broker.url,
  brokerUsername: broker.username,
  brokerPassword: broker.password,
  logger,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Root app={app} />
    </ThemeProvider>
  </React.StrictMode>
);
