import Dialog from "@mui/material/Dialog";
import React, { ReactElement } from "react";
import { Reward } from "../domain/Reward";

export type RewardViewerProps = {
  reward: Reward | null;
};

export function RewardViewer({
  reward,
}: RewardViewerProps): ReactElement | null {
  return (
    <Dialog fullScreen open={reward != null}>
      {reward && (
        <img
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
          src={reward.url}
          alt=""
        ></img>
      )}
    </Dialog>
  );
}
