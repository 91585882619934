import CheckIcon from "@mui/icons-material/Check";
import React, { ReactElement } from "react";
import { Event } from "../domain/Event";
import "./EventList.css";

type TimelineEvent = {
  source: Event;
  title: string;
  isDone: boolean;
  time?: string;
  icon?: JSX.Element;
  imageUrl?: string;
  phase: string;
};

function mapEventsToTimelineItems(events: Event[]): TimelineEvent[] {
  const result: TimelineEvent[] = [];
  let previousEvent: Event | null = null;

  for (const event of events) {
    const isCurrentAction =
      event.isDone === false &&
      (previousEvent === null || previousEvent.isDone === true);

    result.push({
      source: event,
      title: event.title,
      isDone: event.isDone,
      time: event.time,
      icon: (
        <CheckIcon
          style={{ visibility: event.isDone ? "inherit" : "hidden" }}
        />
      ),
      phase: isCurrentAction ? "current" : event.isDone ? "done" : "pending",
      imageUrl: event.imageUrl,
    });

    previousEvent = event;
  }

  return result;
}

export type EventListProps = {
  events: Event[];
  toggleEvent: (event: Event) => void;
};

export function EventList({
  events,
  toggleEvent,
}: EventListProps): ReactElement | null {
  return (
    <div className="event-list">
      {mapEventsToTimelineItems(events).map((event, index) => (
        <div
          key={`event-${index}`}
          className={`event phase-${event.phase}`}
          onClick={() => toggleEvent(event.source)}
        >
          <div className="event-time">{event.time}</div>
          <div className="event-timeline">
            <div className="event-icon">{event.icon}</div>
            <div className="event-divider" />
          </div>
          <div className="event-details">
            <div className="event-title">{event.title}</div>
            <div className="event-image">
              <img src={event.imageUrl} alt={event.title} loading="lazy" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
