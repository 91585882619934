import PQueue from "p-queue";

type ImageCache = {
  [url: string]: HTMLImageElement | null;
};

export class ImagePreloader {
  private queue: PQueue;
  private cache: ImageCache;

  constructor() {
    this.queue = new PQueue({ concurrency: 1 });
    this.cache = {};
  }

  public preload(url: string): Promise<void> {
    if (this.cache[url] === null) {
      return Promise.resolve();
    }

    const task = () => this.cacheImage(url);
    return this.queue.add(task);
  }

  public async preloadList(urls: string[]): Promise<void> {
    const result: Promise<void>[] = [];

    for (const url of urls) {
      result.push(this.preload(url));
    }

    await Promise.all(result);
  }

  private cacheImage(url: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const image = new Image();
      this.cache[url] = image;

      image.onload = () => {
        this.cache[url] = null;
        resolve();
      };

      image.onerror = reject;
      image.onabort = reject;

      image.src = url;
    });
  }
}
