import React, { ReactElement, useEffect, useState } from "react";
import { DailyPlanner } from "../domain/DailyPlanner";
import { Event } from "../domain/Event";
import { Reward } from "../domain/Reward";
import { EventList } from "./EventList";
import { RewardViewer } from "./RewardViewer";
import { sleep } from "./sleep";

type RootProps = {
  app: DailyPlanner;
};

export function Root({ app }: RootProps): ReactElement | null {
  const [reward, setReward] = useState<Reward | null>(null);
  const [events, setEvents] = useState<Event[]>(app.getEvents());

  useEffect(() => {
    app.setListener({
      onEventsChanged(events: Event[]) {
        setEvents([...events]);
      },
      async onRewardEarned(reward: Reward) {
        setReward(reward);
        await sleep(reward.duration);
        setReward(null);
      },
    });
  }, [app]);

  return (
    <>
      <EventList
        events={events}
        toggleEvent={(event) => app.toggleEvent(event)}
      ></EventList>
      <RewardViewer reward={reward}></RewardViewer>
    </>
  );
}
