import { Client } from "@stomp/stompjs";
import { ILogMessages } from "./ILogMessages";

export type ConnectionSetup = (client: Client) => void;

export type BrokerConnectorOptions = {
  url: string;
  username: string;
  password: string;
  logger?: ILogMessages;
  setup: ConnectionSetup;
};

export class BrokerConnector {
  private logger?: ILogMessages;
  private client: Client;
  private setup: ConnectionSetup;

  public constructor({
    url,
    username,
    password,
    logger,
    setup,
  }: BrokerConnectorOptions) {
    this.logger = logger;
    this.setup = setup;
    this.client = this.createClient(url, username, password);
    this.client.activate();
  }

  public getClient(): Client {
    return this.client;
  }

  private createClient(
    url: string,
    username: string,
    password: string
  ): Client {
    const result = new Client({
      brokerURL: url,
      connectHeaders: {
        login: username,
        passcode: password,
      },
      debug: (str) => {
        this.log(str);
      },
    });

    result.onConnect = (frame) => {
      this.log("connected");
      this.setup(result);
    };

    result.onStompError = (frame: any) => {
      // Will be invoked in case of error encountered at Broker
      // Bad login/passcode typically will cause an error
      // Complaint brokers will set `message` header with a brief message. Body may contain details.
      // Compliant brokers will terminate the connection after any error
      this.log("Broker reported error: " + frame.headers["message"]);
      this.log("Additional details: " + frame.body);
    };

    result.onWebSocketError = (event: any) => {
      this.log({ error: "onWebSocketError", event });
    };
    result.onChangeState = (state: any) => {
      this.log({ error: "onChangeState", state });
    };
    result.onDisconnect = () => {
      this.log({ error: "onDisconnect" });
    };
    result.onWebSocketClose = () => {
      this.log({ error: "onWebSocketClose" });
    };

    return result;
  }

  private log(...values: any[]) {
    return this.logger?.log("BrokerConnector", ...values);
  }
}
